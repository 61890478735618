import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PageHeader from '../components/pageHeader.js'
import Footer from '../components/footer.js'
import { CategoryMetadataHeader } from '../components/metadataHeader.js'

import { mapArray } from '../helpers.js'
import page from '../css/modules/genrePage.module.scss'

class CategoryPage extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 1)
  }

  createCategoryCards() {
    let categoryCards = []
    let categoryNodes = []
    this.props.data.allBookJson.edges.map(({ node }, index) => {
      var currentType = node[`${this.props.pageContext.type}`]
      for (var i = 0; i < currentType.length; i++) {
        if (currentType[i].id === this.props.pageContext.id) {
          categoryNodes.push(node)
          continue
        }
      }
    })
    categoryNodes.forEach(function(node, index) {
      categoryCards.push(<CategoryCard node={node} key={index} />)
    })
    return categoryCards
  }

  componentWillUnmount() {
    this.imageBackgrounder = null
  }

  render() {
    return (
      <div
        style={{
          opacity: this.state.loading ? '0' : '1',
          transition: 'opacity 0.1s',
        }}>
        <CategoryMetadataHeader
          category={this.props.pageContext.title}
          canonicalUrl={this.props.data.site.siteMetadata.siteUrl}
        />
        <PageHeader
          searchQuery={this.props.searchQuery}
          history={this.props.history}
          pageTitle={this.props.pageContext.title}
        />
        <div className={page.wrapper}>{this.createCategoryCards()}</div>
        <Footer />
      </div>
    )
  }
}

class CategoryCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      backColor: '',
    }
  }

  render() {
    return (
      <div className={page.item}>
        <div className={page.background}>
          {this.props.node.image ? (
            <img
              alt={`Cover of ${this.props.node.title}`}
              src={this.props.node.image}
              className={page.blurbg}
            />
          ) : null}
          <Link to={'/' + this.props.node.gatsby_slug}>
            <img
              alt={`Cover of ${this.props.node.title}`}
              className={page.image}
              src={this.props.node.image}
            />
          </Link>
        </div>
        <div className={page.textbox}>
          <h3 className="bold-header">
            <Link to={'/' + this.props.node.gatsby_slug}>
              {this.props.node.title}
            </Link>
          </h3>
          <p>{mapArray(this.props.node.book_author)}</p>
        </div>
      </div>
    )
  }
}

export default CategoryPage

export const query = graphql`
  query CategoryPageQuery {
    allBookJson {
      edges {
        node {
          slug
          gatsby_slug
          image
          book_author {
            title
            id
          }
          title
          genre {
            id
          }
          reading_age {
            id
          }
          narrator {
            id
          }
          publisher {
            id
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
