import React from 'react'
import { navigate } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SVG from 'react-inlinesvg'

import xButton from '../static/icons/cross.svg'
import chevronLeft from '../static/icons/chevronLeft.svg'
import pageheader from '../css/modules/pageHeader.module.scss'

export default class PageHeader extends React.Component {
  constructor() {
    super()
    this.goBack = this.goBack.bind(this)
    this.goHome = this.goHome.bind(this)
    const goneBack = false
  }

  goBack() {
    if (!this.goneBack) {
      this.goneBack = true
      return navigate(-1)
    }
  }

  goHome() {
    if (this.props.searchQuery) {
      this.props.searchQuery('')
    }
    navigate('/')
  }

  render() {
    return (
      <div className={pageheader.header}>
        <div className={pageheader.wrapper}>
          <div className={pageheader.title}>
            <div className={pageheader.back} onClick={this.goBack}>
              <SVG src={chevronLeft} />
              <span className={pageheader.backtext}>Back</span>
            </div>

            <h1>{this.props.pageTitle}</h1>
            <div className={pageheader.home} onClick={this.goHome}>
              <SVG src={xButton} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
